import {ImSearch} from "react-icons/im";
import {numberSort, Table, TableColumn} from "@/components/Table";
import React, {ClipboardEvent, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchPerpConfigs2} from "@/api/perpsDataFetcher";
import {PerpConfig2, PerpToken} from "@/components/Perps/types";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import classNames from "classnames";
import {Timeframe, useUserSelection} from "@/contexts/UserSelectionContext";
import {useHasMounted} from "@/hooks/useHasMounted";
import {useRouter} from "next/router";
import {CHAIN_ID, defaultToken, getBaseUrl} from "@/util/constants";
import {getBackendUrl, getChainImage, getChainName} from "@/util/chainConstants";
import {useAccount} from "wagmi";
import {prettifyNumber} from "@/util/converters";
import {useStringQueryParam} from "@/hooks/useQueryParam";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {Tabs} from "@/components/Tabs";
import {Dropdown, DropdownOption} from "@/components/Dropdown";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {EthValue} from "@/components/Perps/EthValue";
import {parseUnits} from "viem";
import {twMerge} from "tailwind-merge";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {TimeRemaining} from "@/components/TimeRemaining";
import {blast} from "@/util/additionalChains";
import {mainnet, sepolia} from "wagmi/chains";

const priorityTokens = ["USDB", "WBTC", "SOL", "BLAST", "Mog", "NEIRO"].reverse();

export interface Props {
  onSelected?: (token: PerpToken) => any | undefined;
  showTokensOnly?: boolean;
  columnsToExclude?: string[];
  className?: string | undefined;
  displayChainSelector?: boolean;
}

const getChainDropdown = (chainId: number) => {
  return {
    label: getChainName(chainId),
    value: chainId,
    icon: getChainImage(chainId)
  } as DropdownOption<number>;
}

const options: DropdownOption<Timeframe>[] = [
  {label: "1H ", value: 1},
  {label: "4H ", value: 4},
  {label: "12H ", value: 12},
  {label: "24H ", value: 24}
];

const selections: DropdownOption<number>[] =[
  {label: "All Chains", value: 0, icon: "/static/wasabi_logo_small.svg"}
];
if (CHAIN_ID === sepolia.id) {
  selections.push(getChainDropdown(sepolia.id));
} else {
  selections.push(getChainDropdown(blast.id));
  selections.push(getChainDropdown(mainnet.id));
}

const defaultSort = (a: PerpConfig2, b: PerpConfig2) => {
  if (a.boost && b.boost) {
    return b.boost.boost - a.boost.boost;
  } else if (a.boost) {
    return -1;
  } else if (b.boost) {
    return 1;
  }
  const aPriority = priorityTokens.indexOf(a.token.symbol);
  const bPriority = priorityTokens.indexOf(b.token.symbol);
  if (aPriority >= 0 && bPriority >= 0) {
    return bPriority - aPriority;
  } else if (aPriority >= 0) {
    return -1;
  } else if (bPriority >= 0) {
    return 1;
  }
  const aOpenInterest = a.openInterest.longOpenInterest.amount + a.openInterest.shortOpenInterest.amount;
  const bOpenInterest = b.openInterest.longOpenInterest.amount + b.openInterest.shortOpenInterest.amount;
  if (aOpenInterest > 0 && bOpenInterest > 0) {
    return bOpenInterest - aOpenInterest;
  } else if (aOpenInterest > 0) {
    return -1;
  } else if (bOpenInterest > 0) {
    return 1;
  }
  return b.tokenStats.oneDayVolumeUsd - a.tokenStats.oneDayVolumeUsd;
}


export const TokensTable = ({onSelected, showTokensOnly, columnsToExclude, className, displayChainSelector = true}: Props) => {
  const {address} = useAccount();
  const isDesktop = useIsDesktop();
  const {ethPrice, showInUsd} = useEthPrice();
  const hasMounted = useHasMounted();
  const router = useRouter();
  const {userSelections, handleSelection} = useUserSelection();
  const [searchText, setSearchText] = useState<string>("");
  const [token, setToken] = useStringQueryParam('token', userSelections.token || defaultToken);

  const filterSearch = (perpConfig: PerpConfig2) => {
    if (searchText.length === 0) {
      return true;
    }
    return perpConfig.token.symbol.toLowerCase().includes(searchText.toLowerCase()) || perpConfig.token.name.toLowerCase().includes(searchText.toLowerCase());
  }

  const [selectedChain, setSelectedChain] = useState(displayChainSelector ? 0 : CHAIN_ID);

  const query = useQuery({
    queryKey: ['perp_configs'],
    queryFn: async () => {
      const data = await fetchPerpConfigs2();
      const otherChains = selections.filter(s => s.value !== 0 && s.value !== CHAIN_ID);
      for (const chain of otherChains) {
        const otherData = await fetchPerpConfigs2(getBackendUrl(chain.value));
        data.items.push(...(otherData.items || []));
      }
      return data;
    },
    refetchInterval: 20 * 1000, // 20 seconds
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchText(inputValue);
  };

  const handleInputPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();
    setSearchText(pastedText);
  };

  const filteredOptions = query.data?.items
    .filter(i => selectedChain === 0 || i.chainId === selectedChain)
    .filter(filterSearch)
    .sort(defaultSort);

  const handleRowClick = (data: PerpConfig2) => {
    if (CHAIN_ID === data.chainId) {
      onSelected && onSelected(data.token);
      setToken(data.token.symbol);
    } else {
      const url = `${getBaseUrl(data.chainId)}/?t=active&side=long&token=${data.token.symbol}`;
      router.push(url);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (hasMounted && router.isReady && event.key === "Enter" && filteredOptions && filteredOptions.length > 0) {
      const selectedToken = filteredOptions[0];
      handleRowClick(selectedToken);
    }
  };

  const getValue = (value: "volume" | "change", token: PerpConfig2) => {
    switch (userSelections.timeframe) {
      case 1:
        return value === "volume" ? token.tokenStats.oneHourVolumeUsd : token.tokenStats.oneHourChange;
      case 4:
        return value === "volume" ? token.tokenStats.fourHourVolumeUsd : token.tokenStats.fourHourChange;
      case 12:
        return value === "volume" ? token.tokenStats.twelveHourVolumeUsd : token.tokenStats.twelveHourChange;
      default:
        return value === "volume" ? token.tokenStats.oneDayVolumeUsd : token.tokenStats.oneDayChange;
    }
  }

  const columns: TableColumn<PerpConfig2>[] = [
    {
      id: "token",
      label: showTokensOnly ? "Token" : "Market",
      valueRenderer: d => {
        const isUSD = d.token.symbol === "USDB";
        const tokenSymbol = (!showTokensOnly && isUSD) ? "ETH" : d.token.symbol;
        const currencyToken = isUSD ? "USDB" : "ETH";

        return <div className="flex flex-row items-center gap-3">
          <div className="relative" id={`market_${d.token.address}_${d.chainId}`}>
            <img
              src={showTokensOnly && d.token.symbol === "USDB" ? "https://wasabi-public.s3.amazonaws.com/tokens/usdb.png" : d.token.imageUrl}
              alt={d.token.symbol}
              className="w-6 h-6 rounded-full border border-glass-focus"
            />
            {
              displayChainSelector &&
              <div className="absolute right-[-8px] bottom-[-8px]">
                <img
                  id={`chain_${d.token.address}`}
                  src={getChainImage(d.chainId)}
                  alt={getChainName(d.chainId)}
                  className="w-4 h-4 rounded-full p-0.5"/>
              </div>
            }
          </div>
          {
            displayChainSelector &&
            <ReactTooltip
              anchorSelect={`#market_${d.token.address}_${d.chainId}`}
              id={`tooltip_market_${d.token.address}_${d.chainId}`}
              noArrow
              content={`Available on ${getChainName(d.chainId)}`}
              className="z-50 p-2 flex flex-row items-center gap-2"
              style={{
                backgroundColor: "#3b485f",
                color: "white"
              }} />
          }
          <div className="flex flex-row gap-3 items-center">
            <span className="text-xs lg:text-base">
              {
                showTokensOnly
                  ? tokenSymbol :
                  <span className="responsive-flex md:items-center md:gap-1">
                    <span className="text-base md:text-xs">{tokenSymbol}</span>
                    <span
                      className="text-neutral-content text-xs">{(isDesktop ? " / " : "") + currencyToken}</span>
                  </span>
              }
            </span>
            {
              d.boost && !showTokensOnly &&
              <>
                <span id={`token_boost_${d.token.address}`}
                      className="text-wasabigreen font-bold flex flex-row gap-1 items-center">
                  {d.boost.boost}x <img src="/static/logo_nobackground.png" className="h-6 w-6" alt="wasabi_earning"/>
                </span>
                <ReactTooltip
                  anchorSelect={`#token_boost_${d.token.address}`}
                  id={`token_boost__tooltip${d.token.address}`}
                  place="top"
                  noArrow
                  className="z-50 p-2 flex flex-row items-center gap-2"
                  style={{
                    backgroundColor: "#3b485f",
                    color: "white"
                  }}
                >
                  This pair earns {d.boost.boost}x Wasabi points on all trades { d.boost.end ? <>for the next <TimeRemaining epochSeconds={d.boost.end} /></> : ''}
                </ReactTooltip>
              </>
            }
          </div>
        </div>;
      },
      span: 3,
      align: "left",
      // sort: stringSort((d) => d.token.symbol),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d)
    },
    {
      id: "marketCap",
      label: isDesktop ? "Market Cap" : <div className="flex flex-col">
        <span>Market Cap</span>
        <span className="text-xs">{userSelections.timeframe}h Volume</span>
      </div>,
      valueRenderer: d => <div className="flex flex-col items-end text-sm">
        <span className="flex flex-row items-center">
          <span className="">$</span>{prettifyNumber(d.tokenStats.marketCap)}
        </span>
        { !isDesktop && <span className="text-neutral-content text-xs">${prettifyNumber(getValue("volume", d))}</span> }
      </div>,
      align: "right",
      hideOnMobile: true,
      span: 2,
      sort: numberSort(d => d.tokenStats.marketCap),
    },
    {
      id: 'openInterest',
      label: 'Open Interest',
      valueRenderer: d =>
        <EthValue
          className="text-sm"
          ethPrice={ethPrice}
          showInUsd={showInUsd}
          value={parseUnits((d.openInterest.longOpenInterest.amount + d.openInterest.shortOpenInterest.amount).toString(), 18)}/>,
      align: "right",
      sort: numberSort(d => d.openInterest.longOpenInterest.amount + d.openInterest.shortOpenInterest.amount),
      hideOnMobile: true,
      span: 2,
    },
    {
      id: `${userSelections.timeframe}hVolume`,
      label: `${userSelections.timeframe}h Volume`,
      valueRenderer: d =>
        <span className="flex flex-row items-center text-sm">
          <span className="">$</span>{prettifyNumber(getValue("volume", d))}
        </span>,
      align: "right",
      sort: numberSort(d => getValue("volume", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      span: 2,
    },
    {
      id: `${userSelections.timeframe}hChange`,
      label: `${userSelections.timeframe}h Change`,
      valueRenderer: d => {
        const value = getValue("change", d);
        return <div className={classNames("text-sm flex items-center", {
          "text-put": value < 0,
          "text-call": value > 0,
          "text-neutral-content": value === 0,
        })}>
          {value > 0 && "+"}
          {value.toFixed(2)}%
        </div>
      },
      align: "right",
      sort: numberSort(d => getValue("change", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      hideOnMobile: true,
      span: 2,
    },
    {
      id: "price",
      label: isDesktop ? "Price" : <div className="flex flex-col">
        <span>Price</span>
        <span className="text-xs">{userSelections.timeframe}h %</span>
      </div>,
      valueRenderer: d => {
        const value = getValue("change", d);
        return (
          <div className="flex flex-col items-end">
            <FractionTokenPrice
              exponentClassName="text-xs text-neutral-content"
              className="text-sm"
              iconSize={12}
              price={showTokensOnly && d.token.symbol === "USDB" ? 1 : Number(d.tokenStats.price)}
              showInUsd={showInUsd}
              showAsFullNFT={false}
              tokenAddress={d.token.address}
              tokenType={d.token.tokenType}
            />
            {
              !isDesktop &&
              <div className={classNames("text-sm flex items-center", {
                "text-put": value < 0,
                "text-call": value > 0,
                "text-neutral-content": value === 0,
              })}>
                {value > 0 && "+"}
                {value.toFixed(2)}%
              </div>
            }
          </div>
        );
      },
      align: "right",
      sort: numberSort((d) => isDesktop ? Number(d.tokenStats.price) : getValue("change", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      span: 2,
    },
  ];

  return (
    <div className={twMerge("w-full flex-grow flex flex-col pb-safe overflow-hidden", className || '')}>
      <div className="flex flex-row gap-2 p-2">
        <div className="flex-grow flex flex-row items-center rounded-md">
          <ImSearch className="searchbar-icon ml-4 mr-4 cursor-pointer" size={18}/>
          <input
            className="h-10 inline-block cursor-pointer border-none outline-none bg-transparent w-full hover:cursor-text"
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={handleInputChange}
            onPaste={handleInputPaste}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleKeyUp(event);
              }
            }}
            autoComplete="off"
          />
        </div>
        {
          isDesktop &&
          <div className="flex justify-end">
            <Tabs<Timeframe>
              borderlessSelected={true}
              className="min-h-[40px] p-0 w-[300px]"
              options={options} value={userSelections.timeframe}
              onChange={(value) => handleSelection('timeframe', value)}
            />
          </div>
        }
        {
          displayChainSelector &&
          <Dropdown options={selections}
                    onChange={e => setSelectedChain(e)}
                    selected={selectedChain}
                    className="min-w-[160px]"
                    optionsImageClassName="rounded-full"
                    optionsClassName="w-[150px]"
                    itemClassName="text-sm"
          />
        }
      </div>
      <hr className="border-neutral-content/20"/>
      <Table<PerpConfig2>
        id={"tokens_" + showTokensOnly}
        className="flex-grow border-none rounded-none no-scrollbar !bg-transparent overflow-hidden"
        columns={columns.filter(c => columnsToExclude === undefined || !columnsToExclude.includes(c.id))}
        data={filteredOptions}
        // initialSort= {userSelections.tokenSearchSort || defaultSort}
        // initialSortDirection="desc"
        isLoading={query.isLoading}
        rowOnClick={handleRowClick}
      />
      {
        !isDesktop &&
        <Tabs<Timeframe>
          className="min-h-[40px] !border-none"
          optionClassName="text-base"
          options={options} value={userSelections.timeframe}
          onChange={(value) => handleSelection('timeframe', value)}
        />
      }
    </div>
  )
}
